/**
 * @generated SignedSource<<f1e846352b4baf9074c58a483a625406>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type openQuestionsSeriesDiscoveryWidgetQuery$data = {
  readonly openQuestionSeriesTopic: {
    readonly contents: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly entityId?: string;
          readonly " $fragmentSpreads": FragmentRefs<"homeSecondaryContentItemContent">;
        };
      }>;
    } | null | undefined;
    readonly name: string;
    readonly urlAlias: string;
  };
  readonly " $fragmentType": "openQuestionsSeriesDiscoveryWidgetQuery";
};
export type openQuestionsSeriesDiscoveryWidgetQuery$key = {
  readonly " $data"?: openQuestionsSeriesDiscoveryWidgetQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"openQuestionsSeriesDiscoveryWidgetQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": [],
      "kind": "LocalArgument",
      "name": "scmpPlusPaywallTypeIds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "openQuestionsSeriesDiscoveryWidgetQuery",
  "selections": [
    {
      "alias": "openQuestionSeriesTopic",
      "args": [
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "entityId": "518656"
          }
        }
      ],
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topic",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "urlAlias",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "paywallTypeIds",
                  "variableName": "scmpPlusPaywallTypeIds"
                }
              ],
              "kind": "ObjectValue",
              "name": "exclude"
            },
            {
              "kind": "Literal",
              "name": "first",
              "value": 6
            },
            {
              "kind": "Literal",
              "name": "orderBy",
              "value": {
                "direction": "DESC",
                "field": "PUBLISHED_DATE"
              }
            }
          ],
          "concreteType": "ContentConnection",
          "kind": "LinkedField",
          "name": "contents",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "entityId",
                          "storageKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "homeSecondaryContentItemContent"
                        }
                      ],
                      "type": "Article",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "topic(filter:{\"entityId\":\"518656\"})"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "be350b9c08baf42c69ebfb566b3d7bf4";

export default node;
