/**
 * @generated SignedSource<<4bbf0a950e07bfd079277189194d7076>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type postMagazineDiscoveryItemContent$data = {
  readonly entityId: string;
  readonly urlAlias: string;
  readonly " $fragmentSpreads": FragmentRefs<"articleCommentArticle" | "entityLink" | "helpersCheckHasLiveTagArticle" | "helpersUseContentSponsorContent" | "hooksContentItemProvidersContent">;
  readonly " $fragmentType": "postMagazineDiscoveryItemContent";
};
export type postMagazineDiscoveryItemContent$key = {
  readonly " $data"?: postMagazineDiscoveryItemContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"postMagazineDiscoveryItemContent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "postMagazineDiscoveryItemContent",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urlAlias",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "articleCommentArticle"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "entityLink"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersCheckHasLiveTagArticle",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "flattenTypeIds",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "LiveArticle",
              "kind": "LinkedField",
              "name": "liveArticle",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Article",
          "abstractKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "helpersUseContentSponsorContent",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Topic",
          "kind": "LinkedField",
          "name": "topics",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Sponsor",
              "kind": "LinkedField",
              "name": "sponsor",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "sponsorTagSponsor"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sponsorType",
              "storageKey": null
            }
          ],
          "type": "Article",
          "abstractKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "helpersCheckIsMainTopicContent",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Topic",
              "kind": "LinkedField",
              "name": "topics",
              "plural": true,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "withCoverImage",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withCoverImageSize1200x800",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withCoverImageSize540x360",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withHeadline",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withReadingTime",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withSummary",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withVideoDurationOverlay",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "hooksContentItemProvidersContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};
})();

(node as any).hash = "37667ff2cbfaa0ba42f4cc0eb48edb3c";

export default node;
