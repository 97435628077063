import styled from "@emotion/styled";
import { fontRobotoCondensed, theme } from "@product/scmp-sdk";
import "swiper/css";
import "swiper/css/grid";
import { SwiperSlide } from "swiper/react";

import { BaseLink } from "shared/components/common/base-link";

import { ContentItemHomeSecondary } from "scmp-app/components/content/content-item-render/variants/home-secondary";
import {
  ActionBar,
  CoverImage,
  Headline,
  StyledCoverEntityLink,
} from "scmp-app/components/content/content-item-render/variants/home-secondary/styles";
import { Swiper } from "scmp-app/components/swiper";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  margin-block-start: 24px;
  padding-block: 24px;
  padding-inline: 20px;

  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
  }
  ${theme.breakpoints.up("desktop")} {
    margin-block-start: 0;
  }
`;

export const Title = styled.div`
  margin-block-end: 16px;

  color: #000000;
  font-weight: 400;
  font-size: 20px;
  font-family: ${fontRobotoCondensed};
  text-transform: uppercase;

  opacity: 0.5;
  ${theme.breakpoints.up("tablet")} {
    margin-block-end: 20px;
  }
  ${theme.breakpoints.up("desktop")} {
    margin-block-end: 24px;
  }
`;

export const StyledSwiper = styled(Swiper)`
  &.swiper {
    padding-block-end: 18px;

    .swiper-pagination {
      .swiper-pagination-bullet {
        margin: 0;

        background-color: #bbbbbb;

        opacity: 0.5;
        &:not(:last-child) {
          margin-inline-end: 8px;
        }
      }

      .swiper-pagination-bullet-active {
        background-color: #000000;

        opacity: 1;
      }
    }
  }
`;

export const StyledSwiperSlide = styled(SwiperSlide)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledContentItemHomeSecondary = styled(ContentItemHomeSecondary)`
  display: flex;
  flex: 1;
  flex-direction: column;

  margin-block-end: 16px;
  padding-block-end: 16px;

  border-block-end: 1px solid rgba(0, 0, 0, 0.1);

  &:not(:last-child) {
    margin-block-end: 16px;
  }

  ${ActionBar} {
    margin-block-end: 16px;
  }

  ${StyledCoverEntityLink} {
    display: block;

    margin-block: 0;

    ${CoverImage} {
      inline-size: 100%;
      block-size: 100%;
      aspect-ratio: 276/144;
    }
  }

  ${Headline} {
    gap: 0;

    color: #000000;
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
  }

  ${StyledCoverEntityLink} {
    ${CoverImage} {
      aspect-ratio: 325/196;
    }
  }
`;

export const MoreLink = styled(BaseLink)`
  align-self: flex-start;

  margin-block-start: 6px;
  padding-block: 7px;
  padding-inline: 8px;

  color: #4585ff;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;

  border: 1px solid #4585ff;
  border-radius: 2px;

  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 4px;
  }
  ${theme.breakpoints.up("desktop")} {
    margin-block-start: 16px;
  }
`;

export const SwiperContainer = styled.div`
  max-inline-size: 100%;

  ${theme.breakpoints.up("tablet")} {
    display: none;
  }
`;

export const TopItems = styled.div`
  display: none;
  ${theme.breakpoints.up("tablet")} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  ${theme.breakpoints.up("desktop")} {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
`;

export const RestItems = styled.div`
  display: none;
  ${theme.breakpoints.up("tablet")} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  ${theme.breakpoints.up("desktop")} {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
`;
